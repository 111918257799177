import { Action, createReducer, on } from '@ngrx/store';
import { getInitialParamsState } from '../../../../utils/getInitialStateFromLocalStorage';
import { ILocationItemsParams } from '../../models/locationItemsParams';
import * as LocationItemsActions from './location-items.actions';

export interface LocationItemsState {
  queryParams: ILocationItemsParams;
  loading: boolean;
  error: boolean;
}
const initialState: LocationItemsState = {
  queryParams: getInitialParamsState<ILocationItemsParams>(
    'locationItemsParams'
  ),
  loading: false,
  error: false,
};

const _locationItemsReducer = createReducer(
  initialState,
  on(LocationItemsActions.changeQueryParams, (state, action) => ({
    ...state,
    queryParams: { ...action.queryParams },
  }))
);

export function locationItemsReducer(
  state: LocationItemsState | undefined,
  action: Action
) {
  return _locationItemsReducer(state, action);
}
