import { Action, createReducer, on } from '@ngrx/store';
import { getInitialParamsState } from '../../../../utils/getInitialStateFromLocalStorage';
import * as ProductionOrdersActions from './production-orders.actions';
import { IProductionOrdersParams } from '../../models/productionOrdersParams';

export interface ProductionOrdersState {
  queryParams: IProductionOrdersParams;
  loading: boolean;
  error: boolean;
}

const initialState: ProductionOrdersState = {
  queryParams: getInitialParamsState<IProductionOrdersParams>(
    'productionOrdersQueryParams'
  ),
  loading: false,
  error: false,
};

const _productionOrdersReducer = createReducer(
  initialState,
  on(ProductionOrdersActions.changeQueryParams, (state, action) => ({
    ...state,
    queryParams: { ...action.queryParams },
  }))
);

export function productionOrdersReducer(
  state: ProductionOrdersState | undefined,
  action: Action
) {
  return _productionOrdersReducer(state, action);
}
