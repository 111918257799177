import { Action, createReducer, on } from '@ngrx/store';
import { User } from '../../core/models/auth.model';
import * as AuthActions from './auth.actions';

export interface AuthState {
  user: User | null;
  loading: boolean;
  error: string | null;
}

const initialState: AuthState = {
  user: null,
  loading: false,
  error: null,
};

const _authReducer = createReducer(
  initialState,
  on(AuthActions.login, (state, action) => ({
    ...state,
    loading: true,
  })),
  on(AuthActions.loginSuccess, (state, action) => ({
    ...state,
    loading: false,
    user: new User(
      action.loginSuccessResponse.user.name,
      action.loginSuccessResponse.user.email,
      action.loginSuccessResponse.user.roles,
      action.loginSuccessResponse.user.mobile_production_version,
      action.loginSuccessResponse.user.mobile_magazine_version
    ),
  })),
  on(AuthActions.loginFailure, (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
  })),
  on(AuthActions.autoLogin, (state, action) => ({
    ...state,
    user: new User(
      action.user.name,
      action.user.email,
      action.user.roles,
      action.user.mobile_production_version,
      action.user.mobile_magazine_version
    ),
  })),
  on(AuthActions.logout, (state, action) => ({
    ...state,
  })),
  on(AuthActions.logoutSuccess, (state, action) => ({
    ...state,
    user: null,
  })),
  on(AuthActions.logoutFailure, (state, action) => ({
    ...state,
  })),
  on(AuthActions.clearError, (state, action) => ({
    ...state,
    error: null,
  }))
);

export function authReducer(state: AuthState | undefined, action: Action) {
  return _authReducer(state, action);
}
