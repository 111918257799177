import { Action, createReducer, on } from '@ngrx/store';
import { getInitialParamsState } from '../../../../utils/getInitialStateFromLocalStorage';
import * as PhotoDocumentationActions from './photo-documentation.actions';
import { IPhotoDocumentationParams } from '../../models/photoDocumentationParams';

export interface PhotoDocumentationState {
  queryParams: IPhotoDocumentationParams;
  loading: boolean;
  error: boolean;
}

const initialState: PhotoDocumentationState = {
  queryParams: getInitialParamsState<IPhotoDocumentationParams>(
    'photoDocumentationQueryParams'
  ),
  loading: false,
  error: false,
};

const _photoDocumentationReducer = createReducer(
  initialState,
  on(PhotoDocumentationActions.changeQueryParams, (state, action) => ({
    ...state,
    queryParams: { ...action.queryParams },
  }))
);

export function photoDocumentationReducer(
  state: PhotoDocumentationState | undefined,
  action: Action
) {
  return _photoDocumentationReducer(state, action);
}
