import { Action, createReducer, on } from '@ngrx/store';
import { getInitialParamsState } from '../../../../utils/getInitialStateFromLocalStorage';
import * as ProductionStandsActions from './production-stands.actions';
import { IProductionStandsParams } from '../../models/productionStandsParams';

export interface ProductionStandsState {
  queryParams: IProductionStandsParams;
  loading: boolean;
  error: boolean;
}

const initialState: ProductionStandsState = {
  queryParams: getInitialParamsState<IProductionStandsParams>(
    'productionStandsParams'
  ),
  loading: false,
  error: false,
};

const _productionStandsReducer = createReducer(
  initialState,
  on(ProductionStandsActions.changeQueryParams, (state, action) => ({
    ...state,
    queryParams: { ...action.queryParams },
  }))
);

export function productionStandsReducer(
  state: ProductionStandsState | undefined,
  action: Action
) {
  return _productionStandsReducer(state, action);
}
