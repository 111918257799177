import { Action, createReducer, on } from '@ngrx/store';
import * as ProductionStatusesActions from './production-statuses.actions';
import { getInitialParamsState } from '../../../../../utils/getInitialStateFromLocalStorage';
import { IProductionStatusesParams } from '../../../models/productionStatusesParams';

export interface ProductionStatusesState {
  queryParams: IProductionStatusesParams;
  loading: boolean;
  error: boolean;
}

const initialState: ProductionStatusesState = {
  queryParams: getInitialParamsState<IProductionStatusesParams>(
    'productionStatusesQueryParams'
  ),
  loading: false,
  error: false,
};

const _productionStatusesReducer = createReducer(
  initialState,
  on(ProductionStatusesActions.changeQueryParams, (state, action) => ({
    ...state,
    queryParams: { ...action.queryParams },
  }))
);

export function productionStatusesReducer(
  state: ProductionStatusesState | undefined,
  action: Action
) {
  return _productionStatusesReducer(state, action);
}
