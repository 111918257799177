import { Action, createReducer, on } from '@ngrx/store';
import { getInitialParamsState } from '../../../../../utils/getInitialStateFromLocalStorage';
import { ICollectivePackagingParams } from '../../../models/collectivePackagingParams';
import * as CollectivePackagingActions from './collective-packaging.actions';

export interface CollectivePackagingState {
  queryParams: ICollectivePackagingParams;
  loading: boolean;
  error: boolean;
}

const initialState: CollectivePackagingState = {
  queryParams: getInitialParamsState<ICollectivePackagingParams>(
    'collectivePackagingQueryParams'
  ),
  loading: false,
  error: false,
};

const _collectivePackagingReducer = createReducer(
  initialState,
  on(CollectivePackagingActions.changeQueryParams, (state, action) => ({
    ...state,
    queryParams: { ...action.queryParams },
  }))
);

export function collectivePackagingReducer(
  state: CollectivePackagingState | undefined,
  action: Action
) {
  return _collectivePackagingReducer(state, action);
}
