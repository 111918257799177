import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { APP_NAVIGATION_LINKS } from '../../navigationLinks/navigationLinks';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrl: './side-menu.component.scss',
})
export class SideMenuComponent implements OnInit {
  items!: MenuItem[];
  menuOpen = false;

  ngOnInit() {
    this.items = [
      {
        label: 'Pulpit',
        icon: 'pi pi-fw pi-desktop',
        routerLink: [APP_NAVIGATION_LINKS.HOME],
        command: () => this.closeMenu(),
      },
      {
        label: 'Kontrahenci',
        icon: 'pi pi-user',
        routerLink: [APP_NAVIGATION_LINKS.CLIENTS],
        command: () => this.closeMenu(),

        items: [],
      },
      {
        label: 'Zasoby',
        icon: 'pi pi-box',
        routerLink: [APP_NAVIGATION_LINKS.RESOURCES],
        command: () => this.closeMenu(),

        items: [],
      },
      {
        label: 'Magazyn',
        icon: 'pi  pi-warehouse',
        items: [
          {
            label: 'Stany magazynowe',
            icon: 'pi  pi-box',
            routerLink: [APP_NAVIGATION_LINKS.INVENTORY_LEVELS],
            command: () => this.closeMenu(),
          },
          {
            label: 'Dokumenty magazynowe',
            icon: 'pi  pi-file',
            routerLink: [APP_NAVIGATION_LINKS.WAREHOUSE_DOCUMENTS],
            command: () => this.closeMenu(),
          },
          {
            label: 'Historia ruchów',
            icon: 'pi  pi-history',
            routerLink: [APP_NAVIGATION_LINKS.LOCATION_HISTORIES],
            command: () => this.closeMenu(),
          },
          {
            label: 'Lokalizacje',
            icon: 'pi  pi-map-marker',
            routerLink: [APP_NAVIGATION_LINKS.LOCATIONS],
            command: () => this.closeMenu(),
          },
          {
            label: 'Magazyny',
            icon: 'pi  pi-table',
            routerLink: [APP_NAVIGATION_LINKS.WAREHOUSES],
            command: () => this.closeMenu(),
          },
          {
            label: 'Towary na lokalizacji',
            icon: 'pi pi-inbox',
            routerLink: [APP_NAVIGATION_LINKS.LOCATION_ITEMS],
            command: () => this.closeMenu(),
          },
          {
            label: 'Iwentaryzacja',
            icon: 'pi pi-calculator',
            routerLink: [APP_NAVIGATION_LINKS.STOCKTAKING],
            command: () => this.closeMenu(),
          },
          {
            label: 'Etykiety',
            icon: 'pi pi-tags',
            routerLink: [APP_NAVIGATION_LINKS.LABELS],
            command: () => this.closeMenu(),
          },
          {
            label: 'Numery partii',
            icon: 'pi pi-book',
            routerLink: [APP_NAVIGATION_LINKS.BATCH_NUMBERS],
            command: () => this.closeMenu(),
          },
          {
            label: 'Fotodokumentacja',
            icon: 'pi pi-image',
            routerLink: [APP_NAVIGATION_LINKS.PHOTO_DOCUMENTATION],
            command: () => this.closeMenu(),
          },
        ],
      },
      {
        label: 'Produkcja',
        icon: 'pi pi-cog',
        items: [
          {
            label: 'Kalendarz',
            icon: 'pi pi-calendar',
            routerLink: [APP_NAVIGATION_LINKS.CALENDAR],
            command: () => this.closeMenu(),
          },
          {
            label: 'Zlecenia produkcyjne',
            icon: 'pi pi-list-check',
            routerLink: [APP_NAVIGATION_LINKS.PRODUCTION_ORDERS],
            command: () => this.closeMenu(),
          },
          {
            label: 'Linie produkcyjne',
            icon: 'pi pi-forward',
            routerLink: [APP_NAVIGATION_LINKS.PRODUCTION_LINES],
            command: () => this.closeMenu(),
          },
          {
            label: 'Stanowiska produkcyjne',
            icon: 'pi pi-wrench',
            routerLink: [APP_NAVIGATION_LINKS.PRODUCTION_STANDS],
            command: () => this.closeMenu(),
          },
          {
            label: 'Jednostki produkcyjne',
            icon: 'pi pi-box',
            routerLink: [APP_NAVIGATION_LINKS.PRODUCTION_UNITS],
            command: () => this.closeMenu(),
          },
          {
            label: 'Meldunki',
            icon: 'pi pi-book',
            routerLink: [APP_NAVIGATION_LINKS.REPORTS],
            command: () => this.closeMenu(),
          },
        ],
      },
      {
        label: 'Zamówienia',
        icon: 'pi pi-file',
        items: [
          {
            label: 'Zamówienia',
            icon: 'pi pi-file',
            routerLink: [APP_NAVIGATION_LINKS.ORDERS],
            command: () => this.closeMenu(),
          },
        ],
      },
      {
        label: 'Administracja',
        icon: 'pi pi-home',
        items: [
          {
            label: 'Użytkownicy',
            icon: 'pi pi-users',
            routerLink: [APP_NAVIGATION_LINKS.USERS],
            command: () => this.closeMenu(),
          },
          {
            label: 'Logi systemowe',
            icon: 'pi pi-server',
            routerLink: [APP_NAVIGATION_LINKS.SYSTEM_LOGS],
            command: () => this.closeMenu(),
          },
          {
            label: 'Aktywność użytkowników',
            icon: 'pi pi-stopwatch',
            routerLink: [APP_NAVIGATION_LINKS.USERS_ACTIVITY],
            command: () => this.closeMenu(),
          },
          {
            label: 'Drukarki',
            icon: 'pi pi-print',
            routerLink: [APP_NAVIGATION_LINKS.PRINTERS],
            command: () => this.closeMenu(),
          },
          {
            label: 'Dane słownikowe',
            icon: 'pi pi-file',
            items: [
              {
                label: 'Statusy zleceń produkcyjnych',
                icon: 'pi pi-info-circle',
                routerLink: [APP_NAVIGATION_LINKS.PRODUCTION_ORDERS_STATUSES],
                command: () => this.closeMenu(),
              },
              {
                label: 'Statusy zamówień',
                icon: 'pi pi-info-circle',
                routerLink: [APP_NAVIGATION_LINKS.ORDERS_STATUSES],
                command: () => this.closeMenu(),
              },
              {
                label: 'Typy zamówień',
                icon: 'pi pi-info-circle',
                routerLink: [APP_NAVIGATION_LINKS.ORDER_TYPES],
                command: () => this.closeMenu(),
              },
              {
                label: 'Opakowania zbiorcze',
                icon: 'pi pi-inbox',
                routerLink: [APP_NAVIGATION_LINKS.COLLECTIVE_PACKAGING],
                command: () => this.closeMenu(),
              },
              {
                label: 'Grupy Klientów',
                icon: 'pi pi-inbox',
                routerLink: [APP_NAVIGATION_LINKS.CLIENTS_GROUPS],
                command: () => this.closeMenu(),
              },
              {
                label: 'Kategorie Zasobów',
                icon: 'pi pi-inbox',
                routerLink: [APP_NAVIGATION_LINKS.ITEM_CATEGORIES],
                command: () => this.closeMenu(),
              },
              {
                label: 'Grupy Zasobów',
                icon: 'pi pi-inbox',
                routerLink: [APP_NAVIGATION_LINKS.ITEM_GROUPS],
                command: () => this.closeMenu(),
              },
              {
                label: 'Grupy Opakowań',
                icon: 'pi pi-info-circle',
                routerLink: [APP_NAVIGATION_LINKS.PACKAGING_GROUPS],
                command: () => this.closeMenu(),
              },
              {
                label: 'Opakowania',
                icon: 'pi pi-info-circle',
                routerLink: [APP_NAVIGATION_LINKS.PACKAGING],
                command: () => this.closeMenu(),
              },
              {
                label: 'Typy opakowań',
                icon: 'pi pi-info-circle',
                routerLink: [APP_NAVIGATION_LINKS.PACKAGING_TYPES],
                command: () => this.closeMenu(),
              },
            ],
          },
        ],
      },
    ];
  }

  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }

  closeMenu() {
    this.menuOpen = false;
  }
}
