import { Action, createReducer, on } from '@ngrx/store';
import { getInitialParamsState } from '../../../../utils/getInitialStateFromLocalStorage';
import * as BatchNumbersActions from './batch-numbers.actions';
import { IBatchNumbersParams } from '../../models/batchNumbersParams';

export interface BatchNumbersState {
  queryParams: IBatchNumbersParams;
  loading: boolean;
  error: boolean;
}

const initialState: BatchNumbersState = {
  queryParams: getInitialParamsState<IBatchNumbersParams>(
    'batchNumbersQueryParams'
  ),
  loading: false,
  error: false,
};

const _batchNumbersReducer = createReducer(
  initialState,
  on(BatchNumbersActions.changeQueryParams, (state, action) => ({
    ...state,
    queryParams: { ...action.queryParams },
  }))
);

export function batchNumbersReducer(
  state: BatchNumbersState | undefined,
  action: Action
) {
  return _batchNumbersReducer(state, action);
}
