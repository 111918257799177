import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { CustomValidationError } from '../../../utils/CustomValidationError';

@Injectable()
export class ErrorHandlingInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMsg = '';

        if (error.status === 401) {
          this.router.navigateByUrl('/logout');
        }
        if (error.status === 422) {
          const errorDetails = error.error.errors;

          return throwError(
            () =>
              new CustomValidationError('Wystąpił błąd walidacji', errorDetails)
          );
        }
        errorMsg = error.error.message || 'Wystąpił błąd. Spróbuj ponownie.';

        return throwError(() => new Error(errorMsg));
      })
    );
  }
}
