import { Action, createReducer, on } from '@ngrx/store';
import { getInitialParamsState } from '../../../../../utils/getInitialStateFromLocalStorage';
import * as OrderStatusesActions from './order-statuses.actions';
import { IOrderStatusesParams } from '../../../models/orderStatusesParams';

export interface OrderStatusesState {
  queryParams: IOrderStatusesParams;
  loading: boolean;
  error: boolean;
}

const initialState: OrderStatusesState = {
  queryParams: getInitialParamsState<IOrderStatusesParams>(
    'orderStatusesQueryParams'
  ),
  loading: false,
  error: false,
};

const _orderStatusesReducer = createReducer(
  initialState,
  on(OrderStatusesActions.changeQueryParams, (state, action) => ({
    ...state,
    queryParams: { ...action.queryParams },
  }))
);

export function orderStatusesReducer(
  state: OrderStatusesState | undefined,
  action: Action
) {
  return _orderStatusesReducer(state, action);
}
