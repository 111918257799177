import { Action, createReducer, on } from '@ngrx/store';
import * as PackagingActions from './packaging.actions';
import { getInitialParamsState } from '../../../../../utils/getInitialStateFromLocalStorage';
import { IPackagingParams } from '../../../models/packagingParams';

export interface PackagingState {
  queryParams: IPackagingParams;
  loading: boolean;
  error: boolean;
}

const initialState: PackagingState = {
  queryParams: getInitialParamsState<IPackagingParams>('packagingQueryParams'),
  loading: false,
  error: false,
};

const _packagingReducer = createReducer(
  initialState,
  on(PackagingActions.changeQueryParams, (state, action) => ({
    ...state,
    queryParams: { ...action.queryParams },
  }))
);

export function packagingReducer(
  state: PackagingState | undefined,
  action: Action
) {
  return _packagingReducer(state, action);
}
