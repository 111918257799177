import { UserRoles } from './userRoles';

export interface LoginResponse {
  status: boolean;
  token: string;
  user: IUser;
}

export interface LogoutResponse {
  status: boolean;
}

export interface IUser {
  name: string;
  email: string;
  roles: UserRoles[];
  mobile_magazine_version: string | null;
  mobile_production_version: string | null;
}

export class User implements IUser {
  constructor(
    public name: string,
    public email: string,
    private _roles: UserRoles[],
    private _mobile_magazine_version: string | null,
    private _mobile_production_version: string | null
  ) {}

  get roles() {
    return this._roles;
  }
  get mobile_magazine_version() {
    return this._mobile_magazine_version;
  }
  get mobile_production_version() {
    return this._mobile_production_version;
  }

  public canEdit(role: string) {
    return this.roles.some((element) => element === role);
  }
}

export interface LoginData {
  email: string;
  password: string;
}
