import { Action, createReducer, on } from '@ngrx/store';
import { getInitialParamsState } from '../../../../utils/getInitialStateFromLocalStorage';
import { IProductionLinesParams } from '../../models/productionLinesParams';
import * as ProductionLinesActions from './production-lines.actions';

export interface ProductionLinesState {
  queryParams: IProductionLinesParams;
  loading: boolean;
  error: boolean;
}

const initialState: ProductionLinesState = {
  queryParams: getInitialParamsState<IProductionLinesParams>(
    'productionLinesParams'
  ),
  loading: false,
  error: false,
};

const _productionLinesReducer = createReducer(
  initialState,
  on(ProductionLinesActions.changeQueryParams, (state, action) => ({
    ...state,
    queryParams: { ...action.queryParams },
  }))
);

export function productionLinesReducer(
  state: ProductionLinesState | undefined,
  action: Action
) {
  return _productionLinesReducer(state, action);
}
