import { Action, createReducer, on } from '@ngrx/store';
import { getInitialParamsState } from '../../../../utils/getInitialStateFromLocalStorage';
import { IOrdersParams } from '../../models/ordersParams';
import * as OrdersActions from './orders.actions';

export interface OrdersState {
  queryParams: IOrdersParams;
  loading: boolean;
  error: boolean;
}

const initialState: OrdersState = {
  queryParams: getInitialParamsState<IOrdersParams>('ordersQueryParams'),
  loading: false,
  error: false,
};

const _ordersReducer = createReducer(
  initialState,
  on(OrdersActions.changeQueryParams, (state, action) => ({
    ...state,
    queryParams: { ...action.queryParams },
  }))
);

export function ordersReducer(state: OrdersState | undefined, action: Action) {
  return _ordersReducer(state, action);
}
