import { Action, createReducer, on } from '@ngrx/store';
import { IInventoryLevelsParams } from '../../models/inventoryLevelsParams';
import * as InventoryLevelsActions from './inventory-levels.actions';
import { getInitialParamsState } from '../../../../utils/getInitialStateFromLocalStorage';

export interface InventoryLevelsState {
  queryParams: IInventoryLevelsParams;
  loading: boolean;
  error: boolean;
}

const initialState: InventoryLevelsState = {
  queryParams: getInitialParamsState<IInventoryLevelsParams>(
    'inventoryLevelsParams'
  ),
  loading: false,
  error: false,
};

const _inventoryLevelsReducer = createReducer(
  initialState,
  on(InventoryLevelsActions.changeQueryParams, (state, action) => ({
    ...state,
    queryParams: { ...action.queryParams },
  }))
);

export function inventoryLevelsReducer(
  state: InventoryLevelsState | undefined,
  action: Action
) {
  return _inventoryLevelsReducer(state, action);
}
