import { Component } from '@angular/core';
import { ThemeSwitcherService } from '../../../services/theme-switcher.service';
import { Subscription } from 'rxjs';
import { AppState } from '../../../../../store/app.reducer';
import { Store, select } from '@ngrx/store';
import * as AuthActions from '../../../../../modules/auth/store/auth.actions';
import { selectAuthUser } from '../../../../auth/store/auth.selectors';

@Component({
  selector: 'app-info-bar',
  templateUrl: './info-bar.component.html',
  styleUrl: './info-bar.component.scss',
})
export class InfoBarComponent {
  sidebarVisible = false;
  isDarkMode!: boolean;
  private subscriptions: Subscription = new Subscription();
  user = {
    email: '',
    name: '',
  };
  constructor(
    private themeSwitcher: ThemeSwitcherService,
    private store: Store<AppState>
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.themeSwitcher.currentTheme.subscribe((theme) => {
        this.isDarkMode = theme.includes('dark');
      })
    );
    this.subscriptions.add(
      this.store.pipe(select(selectAuthUser)).subscribe({
        next: (data) => {
          if (data) {
            this.user.email = data.email;
            this.user.email = data.name;
          }
        },
      })
    );
  }
  changeThemeColor(themeColor: string) {
    this.themeSwitcher.changeThemeColor(themeColor);
  }
  switchTheme(theme: string): void {
    this.themeSwitcher.switchTheme(theme);
  }

  switchThemeMode() {
    this.themeSwitcher.toggleThemeMode();
  }

  logout() {
    this.store.dispatch(AuthActions.logout());
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
