import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { inject } from '@angular/core';

export const authGuard: CanActivateFn = (route, state) => {
  const user = inject(AuthService);
  const router = inject(Router);

  return user.isUserLogIn() ? true : router.navigate(['/login']);
};
