<p-toolbar>
  <div class="p-toolbar-group-start">
    <app-breadcrumb></app-breadcrumb>
  </div>
  <!-- ukryte na czas pokazania -->
  <div class="p-toolbar-group-center hidden">
    <app-search-input></app-search-input>
  </div>
  <div class="p-toolbar-group-end">
    <app-info-bar></app-info-bar>
  </div>
</p-toolbar>
