import { Action, createReducer, on } from '@ngrx/store';
import { getInitialParamsState } from '../../../../utils/getInitialStateFromLocalStorage';
import * as SystemLogsActions from './system-logs.actions';
import { ISystemLogsParams } from '../../models/systemLogsParams';

export interface SystemLogsState {
  queryParams: ISystemLogsParams;
  loading: boolean;
  error: boolean;
}

const initialState: SystemLogsState = {
  queryParams: getInitialParamsState<ISystemLogsParams>(
    'systemLogsQueryParams'
  ),
  loading: false,
  error: false,
};

const _systemLogsReducer = createReducer(
  initialState,
  on(SystemLogsActions.changeQueryParams, (state, action) => ({
    ...state,
    queryParams: { ...action.queryParams },
  }))
);

export function systemLogsReducer(
  state: SystemLogsState | undefined,
  action: Action
) {
  return _systemLogsReducer(state, action);
}
