import { Action, createReducer, on } from '@ngrx/store';
import { getInitialParamsState } from '../../../../utils/getInitialStateFromLocalStorage';
import * as ProductionUnitsActions from './production-units.actions';
import { IProductionUnitsParams } from '../../models/productionUnitsParams';

export interface ProductionUnitsState {
  queryParams: IProductionUnitsParams;
  loading: boolean;
  error: boolean;
}

const initialState: ProductionUnitsState = {
  queryParams: getInitialParamsState<IProductionUnitsParams>(
    'productionUnitsParams'
  ),
  loading: false,
  error: false,
};

const _productionUnitsReducer = createReducer(
  initialState,
  on(ProductionUnitsActions.changeQueryParams, (state, action) => ({
    ...state,
    queryParams: { ...action.queryParams },
  }))
);

export function productionUnitsReducer(
  state: ProductionUnitsState | undefined,
  action: Action
) {
  return _productionUnitsReducer(state, action);
}
