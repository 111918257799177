import { Action, createReducer, on } from '@ngrx/store';
import { getInitialParamsState } from '../../../../../utils/getInitialStateFromLocalStorage';
import { IOrderTypesParams } from '../../../models/orderTypesParams';
import * as OrderTypesActions from './order-types.actions';

export interface OrderTypesState {
  queryParams: IOrderTypesParams;
  loading: boolean;
  error: boolean;
}

const initialState: OrderTypesState = {
  queryParams: getInitialParamsState<IOrderTypesParams>(
    'orderTypesQueryParams'
  ),
  loading: false,
  error: false,
};

const _orderTypesReducer = createReducer(
  initialState,
  on(OrderTypesActions.changeQueryParams, (state, action) => ({
    ...state,
    queryParams: { ...action.queryParams },
  }))
);

export function orderTypesReducer(
  state: OrderTypesState | undefined,
  action: Action
) {
  return _orderTypesReducer(state, action);
}
