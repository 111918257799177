<div class="flex align-items-center">
  {{ user.email }}
  <p-button
    icon="pi pi-cog"
    [rounded]="true"
    size="small"
    [text]="true"
    (click)="sidebarVisible = true"
    severity="help"
  ></p-button>
  <p-button
    icon="pi pi-sign-out"
    [text]="true"
    [rounded]="true"
    size="small"
    severity="danger"
    (click)="logout()"
  ></p-button>
  <p-avatar label="P" styleClass="ml-2" shape="circle" size="large"></p-avatar>
</div>
<p-sidebar [(visible)]="sidebarVisible" position="right">
  <h2 class="sidebar-options-heading">Ustawienia:</h2>

  <h3 class="sidebar-options-heading">Tryb Ciemny</h3>
  <p-inputSwitch
    [(ngModel)]="isDarkMode"
    (onChange)="switchThemeMode()"
  ></p-inputSwitch>
  <h3 class="sidebar-options-heading">Wybierz motyw aplikacji</h3>
  <div class="switch-theme-btns-wrapper">
    <button
      class="theme-switcher-button theme-switcher-button--amber"
      (click)="changeThemeColor('light-amber')"
    ></button>

    <button
      class="theme-switcher-button theme-switcher-button--blue"
      (click)="changeThemeColor('light-blue')"
    ></button>
    <button
      class="theme-switcher-button theme-switcher-button--cyan"
      (click)="changeThemeColor('light-cyan')"
    ></button>
    <button
      class="theme-switcher-button theme-switcher-button--green"
      (click)="changeThemeColor('light-green')"
    ></button>
    <button
      class="theme-switcher-button theme-switcher-button--indigo"
      (click)="changeThemeColor('light-indigo')"
    ></button>
    <button
      class="theme-switcher-button theme-switcher-button--pink"
      (click)="changeThemeColor('light-pink')"
    ></button>
    <button
      class="theme-switcher-button theme-switcher-button--purple"
      (click)="changeThemeColor('light-purple')"
    ></button>
    <button
      class="theme-switcher-button theme-switcher-button--teal"
      (click)="changeThemeColor('light-teal')"
    ></button>
  </div>
</p-sidebar>
