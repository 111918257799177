import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { LogoutComponent } from './modules/auth/components/logout/logout.component';
import { unauthGuard } from './modules/core/guards/unauth.guard';
import { authGuard } from './modules/core/guards/auth.guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
    canActivate: [unauthGuard],
  },
  { path: 'logout', component: LogoutComponent },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [authGuard],
    children: [
      {
        path: 'home',
        loadChildren: () =>
          import('./modules/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'clients',
        loadChildren: () =>
          import('./modules/clients/clients.module').then(
            (m) => m.ClientsModule
          ),
      },
      {
        path: 'resources',
        loadChildren: () =>
          import('./modules/resources/resources.module').then(
            (m) => m.ResourcesModule
          ),
      },
      {
        path: 'warehouse',
        loadChildren: () =>
          import('./modules/warehouse/warehouse-routing.module').then(
            (m) => m.WarehouseRoutingModule
          ),
      },
      {
        path: 'production',
        loadChildren: () =>
          import('./modules/production/production-routing.module').then(
            (m) => m.ProductionRoutingModule
          ),
      },
      {
        path: 'orders',
        loadChildren: () =>
          import('./modules/orders/orders-bookmark-routing.module').then(
            (m) => m.OrdersBookmarkRoutingModule
          ),
      },
      {
        path: 'administration',
        loadChildren: () =>
          import('./modules/administration/administration-routing.module').then(
            (m) => m.AdministrationRoutingModule
          ),
      },
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
