import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { filter } from 'rxjs';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrl: './breadcrumb.component.scss',
})
export class BreadcrumbComponent {
  items!: MenuItem[] | undefined;

  home!: MenuItem | undefined;

  constructor(private router: Router) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.updateBreadcrumb();
      });
  }
  updateBreadcrumb(): void {
    // Zaktualizuj 'items' na podstawie ścieżki URL
    const paths = this.router.url.split('/').filter((path) => path.length > 0);
    this.items = paths.map((path, index) => {
      return {
        label: path, // Użyj nazwy ścieżki jako etykiety
        url: '/' + paths.slice(0, index + 1).join('/'), // Oblicz URL do tego punktu
      };
    });
  }
  ngOnInit() {
    // this.items = [
    //   { label: 'Computer' },
    //   { label: 'Notebook' },
    //   { label: 'Accessories' },
    //   { label: 'Backpacks' },
    //   { label: 'Item' },
    // ];

    this.home = { icon: 'pi pi-home', routerLink: '/' };
  }
}
