import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { LoginData, LoginResponse, LogoutResponse } from '../models/auth.model';
import { Observable } from 'rxjs';
import { CookieService } from './cookie.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private apiService: ApiService,
    private cookieService: CookieService
  ) {}

  login(body: LoginData): Observable<LoginResponse> {
    return this.apiService.post<LoginData, LoginResponse>('auth/login', body);
  }

  logout(): Observable<LogoutResponse> {
    return this.apiService.get<LogoutResponse>('auth/logout');
  }

  isUserLogIn(): boolean {
    return this.cookieService.getCookie('token') ? true : false;
  }
  getCSRFCookie() {
    return this.apiService.getCSRFCookie();
  }
}
