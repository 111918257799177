import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from './store/app.reducer';
import { LocalStorageService } from './modules/core/services/local-storage.service';
import { IUser } from './modules/core/models/auth.model';
import * as AuthActions from './modules/auth/store/auth.actions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  title = 'proplast-front';
  constructor(
    private store: Store<AppState>,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    const user = this.localStorageService.read<IUser>('user');
    if (user) {
      this.store.dispatch(AuthActions.autoLogin({ user }));
    }
  }
}
