import { Action, createReducer, on } from '@ngrx/store';
import * as ProductionReportsActions from './production-reports.action';
import { getInitialParamsState } from '../../../../utils/getInitialStateFromLocalStorage';
import { IProductionReportsParams } from '../../models/productionReportsParams';

export interface ProductionReportsState {
  queryParams: IProductionReportsParams;
  loading: boolean;
  error: boolean;
}

const initialState: ProductionReportsState = {
  queryParams: getInitialParamsState<IProductionReportsParams>(
    'productionReportsQueryParams'
  ),
  loading: false,
  error: false,
};

const _productionReportsReducer = createReducer(
  initialState,
  on(ProductionReportsActions.changeQueryParams, (state, action) => ({
    ...state,
    queryParams: { ...action.queryParams },
  }))
);

export function productionReportsReducer(
  state: ProductionReportsState | undefined,
  action: Action
) {
  return _productionReportsReducer(state, action);
}
