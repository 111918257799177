import { Action, createReducer, on } from '@ngrx/store';
import { getInitialParamsState } from '../../../../../utils/getInitialStateFromLocalStorage';
import * as ItemCategoriesActions from './item-categories.actions';
import { IItemCategoriesParams } from '../../../models/itemCategoriesParams';

export interface ItemCategoriesState {
  queryParams: IItemCategoriesParams;
  loading: boolean;
  error: boolean;
}

const initialState: ItemCategoriesState = {
  queryParams: getInitialParamsState<IItemCategoriesParams>(
    'itemCategoriesQueryParams'
  ),
  loading: false,
  error: false,
};

const _itemCategoriesReducer = createReducer(
  initialState,
  on(ItemCategoriesActions.changeQueryParams, (state, action) => ({
    ...state,
    queryParams: { ...action.queryParams },
  }))
);

export function itemCategoriesReducer(
  state: ItemCategoriesState | undefined,
  action: Action
) {
  return _itemCategoriesReducer(state, action);
}
