import { Action, createReducer, on } from '@ngrx/store';
import { getInitialParamsState } from '../../../utils/getInitialStateFromLocalStorage';
import { IClientsParams } from '../models/clientsParams';
import * as ClientsActions from './clients.actions';

export interface ClientsState {
  queryParams: IClientsParams;
  loading: boolean;
  error: boolean;
}

const initialState: ClientsState = {
  queryParams: getInitialParamsState<IClientsParams>('clientsQueryParams'),
  loading: false,
  error: false,
};

const _clientsReducer = createReducer(
  initialState,
  on(ClientsActions.changeQueryParams, (state, action) => ({
    ...state,
    queryParams: { ...action.queryParams },
  }))
);

export function clientsReducer(
  state: ClientsState | undefined,
  action: Action
) {
  return _clientsReducer(state, action);
}
