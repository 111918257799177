import { Action, createReducer, on } from '@ngrx/store';
import { getInitialParamsState } from '../../../../../utils/getInitialStateFromLocalStorage';
import { IPackagingGroupsParams } from '../../../models/packagingGroupsParams';
import * as PackagingGroupsActions from './packaging-groups.actions';

export interface PackagingGroupsState {
  queryParams: IPackagingGroupsParams;
  loading: boolean;
  error: boolean;
}

const initialState: PackagingGroupsState = {
  queryParams: getInitialParamsState<IPackagingGroupsParams>(
    'packagingGroupsQueryParams'
  ),
  loading: false,
  error: false,
};

const _packagingGroupsReducer = createReducer(
  initialState,
  on(PackagingGroupsActions.changeQueryParams, (state, action) => ({
    ...state,
    queryParams: { ...action.queryParams },
  }))
);

export function packagingGroupsReducer(
  state: PackagingGroupsState | undefined,
  action: Action
) {
  return _packagingGroupsReducer(state, action);
}
