import { Action, createReducer, on } from '@ngrx/store';
import { IWarehousesParams } from '../../models/warehousesFilters.model';
import * as WarehousesActions from './warehouses.actions';
import { getInitialParamsState } from '../../../../utils/getInitialStateFromLocalStorage';

export interface WarehousesState {
  queryParams: IWarehousesParams;
  loading: boolean;
  error: boolean;
}

const initialState: WarehousesState = {
  queryParams: getInitialParamsState<IWarehousesParams>(
    'warehousesListQueryParams'
  ),
  loading: false,
  error: false,
};

const _warehousesReducer = createReducer(
  initialState,
  on(WarehousesActions.changeQueryParams, (state, action) => ({
    ...state,
    queryParams: { ...action.queryParams },
  }))
);

export function warehousesReducer(
  state: WarehousesState | undefined,
  action: Action
) {
  return _warehousesReducer(state, action);
}
