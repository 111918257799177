<div class="flex align-items-stretch min-h-screen">
  <app-side-menu class="menu-main"></app-side-menu>

  <div class="page-container flex flex-column overflow-hidden">
    <app-header></app-header>
    <main class="flex-grow-1 py-5 px-3 md:py-8 md:px-8">
      <app-loader></app-loader>
      <router-outlet></router-outlet>
    </main>
  </div>
</div>
