<button class="burger" (click)="toggleMenu()">
  <i class="pi pi-bars"></i>
</button>
<nav [class.open]="menuOpen">
  <div class="logo-container mb-4">
    <h1 class="logo-text">
      EPP
      <span class="logo-text--span">WMS</span>
    </h1>
  </div>
  <p-panelMenu [model]="items" (onItemClick)="closeMenu()"></p-panelMenu>
</nav>
