import { Action, createReducer, on } from '@ngrx/store';
import { getInitialParamsState } from '../../../../utils/getInitialStateFromLocalStorage';
import { IUsersParams } from '../../models/usersParams';
import * as UsersActions from './users.actions';

export interface UsersState {
  queryParams: IUsersParams;
  loading: boolean;
  error: boolean;
}

const initialState: UsersState = {
  queryParams: getInitialParamsState<IUsersParams>('usersQueryParams'),
  loading: false,
  error: false,
};

const _usersReducer = createReducer(
  initialState,
  on(UsersActions.changeQueryParams, (state, action) => ({
    ...state,
    queryParams: { ...action.queryParams },
  }))
);

export function usersReducer(state: UsersState | undefined, action: Action) {
  return _usersReducer(state, action);
}
