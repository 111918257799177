import { StorageKeys } from '../modules/core/models/local-storage.model';

export const getInitialParamsState = <T>(localStorageKey: StorageKeys): T => {
  const savedState = localStorage.getItem(localStorageKey);
  return savedState
    ? (JSON.parse(savedState) as T)
    : ({
        sortOptions: {
          sort: 'id',
          limit: '10',
          order: 'asc',
          page: '1',
        },
        filters: {},
      } as T);
};
