import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './modules/core/core.module';
import { StoreModule } from '@ngrx/store';
import { warehousesReducer } from './modules/warehouse/warehouses/store/warehouses.reducers';
import { LayoutComponent } from './layout/layout.component';
import { authReducer } from './modules/auth/store/auth.reducer';
import { AuthEffects } from './modules/auth/store/auth.effects';
import { EffectsModule } from '@ngrx/effects';
import { ToastModule } from 'primeng/toast';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandlingInterceptor } from './modules/core/interceptors/error-handling.interceptor';
import { Router } from '@angular/router';
import { AuthInterceptor } from './modules/core/interceptors/auth.interceptor';
import { CookieService } from './modules/core/services/cookie.service';
import { locationsReducer } from './modules/warehouse/locations/store/locations.reducer';
import localePL from '@angular/common/locales/pl';
import { registerLocaleData } from '@angular/common';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { inventoryLevelsReducer } from './modules/warehouse/inventory-levels/store/inventory-levels.reducer';
import { warehouseDocumentsReducer } from './modules/warehouse/warehouse-documents/store/warehouse-documents.reducers';
import { locationHistoriesReducer } from './modules/warehouse/location-histories/store/location-histories.reducer';
import { productionUnitsReducer } from './modules/production/production-units/store/production-units.reducer';
import { productionStandsReducer } from './modules/production/production-stands/store/production-stands.reducer';
import { productionLinesReducer } from './modules/production/production-lines/store/production-lines.reducer';
import { LoadingInterceptor } from './modules/core/interceptors/loading-interceptor.interceptor';
import { clientsReducer } from './modules/clients/store/clients.reducers';
import { locationItemsReducer } from './modules/warehouse/location-items/store/location-items.reducer';
import { resourcesReducer } from './modules/resources/store/resources.reducers';
import { batchNumbersReducer } from './modules/warehouse/batch-numbers/store/batch-numbers.reducers';
import { ordersReducer } from './modules/orders/orders/store/orders.reducers';
import { usersReducer } from './modules/administration/users/store/users.reducers';
import { usersActivitiesReducer } from './modules/administration/users-activities/store/users-activities.reducers';
import { printersReducer } from './modules/administration/printers/store/printers.reducers';
import { systemLogsReducer } from './modules/administration/system-logs/store/system-logs.reducers';
import { collectivePackagingReducer } from './modules/administration/dictionary-data/collective-packaging/store/collective-packaging.reducers';
import { orderTypesReducer } from './modules/administration/dictionary-data/order-types/store/order-types.reducers';
import { productionOrdersReducer } from './modules/production/production-orders/store/production-orders.reducers';
import { clientsGroupsReducer } from './modules/administration/dictionary-data/clients-groups/store/clients-groups.reducers';
import { itemCategoriesReducer } from './modules/administration/dictionary-data/item-categories/store/item-categories.reducers';
import { itemGroupsReducer } from './modules/administration/dictionary-data/item-groups/store/item-groups.reducers';
import { orderStatusesReducer } from './modules/administration/dictionary-data/order-statuses/store/order-statuses.reducers';
import { packagingTypesReducer } from './modules/administration/dictionary-data/packaging-types/store/packaging-types.reducers';
import { packagingReducer } from './modules/administration/dictionary-data/packaging/store/packaging.reducers';
import { productionStatusesReducer } from './modules/administration/dictionary-data/production-statuses/store/production-statuses.reducers';
import { photoDocumentationReducer } from './modules/warehouse/photo-documentation/store/photo-documentation.selectors';
import { stocktakingReducer } from './modules/warehouse/stocktaking/store/stocktaking.reducers';
import { packagingGroupsReducer } from './modules/administration/dictionary-data/packaging-groups/store/packaging-groups.reducers';
import {productionReportsReducer} from "./modules/production/production-reports/store/production-reports.reducers";


registerLocaleData(localePL);

@NgModule({
  declarations: [AppComponent, LayoutComponent],
  imports: [
    CoreModule,
    BrowserModule,
    BrowserAnimationsModule,
    ToastModule,
    ConfirmPopupModule,
    StoreModule.forRoot({
      auth: authReducer,
      warehouses: warehousesReducer,
      locations: locationsReducer,
      inventoryLevels: inventoryLevelsReducer,
      warehouseDocuments: warehouseDocumentsReducer,
      locationHistories: locationHistoriesReducer,
      productionUnits: productionUnitsReducer,
      productionStands: productionStandsReducer,
      productionLines: productionLinesReducer,
      clients: clientsReducer,
      locationItems: locationItemsReducer,
      resources: resourcesReducer,
      batchNumbers: batchNumbersReducer,
      orders: ordersReducer,
      users: usersReducer,
      usersActivities: usersActivitiesReducer,
      printers: printersReducer,
      systemLogs: systemLogsReducer,
      collectivePackaging: collectivePackagingReducer,
      orderTypes: orderTypesReducer,
      productionOrders: productionOrdersReducer,
      clientsGroups: clientsGroupsReducer,
      itemCategories: itemCategoriesReducer,
      itemGroups: itemGroupsReducer,
      orderStatuses: orderStatusesReducer,
      packagingTypes: packagingTypesReducer,
      packaging: packagingReducer,
      productionStatuses: productionStatusesReducer,
      photoDocumentation: photoDocumentationReducer,
      stocktaking: stocktakingReducer,
      packagingGroups: packagingGroupsReducer,
      productionReports: productionReportsReducer
    }),
    EffectsModule.forRoot([AuthEffects]),
    AppRoutingModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlingInterceptor,
      multi: true,
      deps: [Router],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
      deps: [CookieService],
    },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
