import { Action, createReducer, on } from '@ngrx/store';
import * as LocalizationsActions from './locations.actions';
import { ILocationQueryParams } from '../../models/location-query-params.model';
import { getInitialParamsState } from '../../../../utils/getInitialStateFromLocalStorage';

export interface LocationsState {
  queryParams: ILocationQueryParams;
  loading: boolean;
  error: boolean;
}

const initialState: LocationsState = {
  queryParams: getInitialParamsState<ILocationQueryParams>(
    'locationsListParams'
  ),
  loading: false,
  error: false,
};

const _locationsReducer = createReducer(
  initialState,
  on(LocalizationsActions.changeQueryParams, (state, action) => ({
    ...state,
    queryParams: { ...action.queryParams },
  }))
);

export function locationsReducer(
  state: LocationsState | undefined,
  action: Action
) {
  return _locationsReducer(state, action);
}
