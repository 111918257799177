import { Injectable } from '@angular/core';
import { AuthService } from '../../core/services/auth.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as AuthActions from './auth.actions';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { CookieService } from '../../core/services/cookie.service';
import { Router } from '@angular/router';
import { NotificationService } from '../../core/services/notification.service';
import { LocalStorageService } from '../../core/services/local-storage.service';

@Injectable()
export class AuthEffects {
  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logout),
      switchMap(() => {
        return this.authService.logout().pipe(
          tap(() => {
            this.cookieService.deleteCookie('token');
            this.localStorageService.remove('user');
            this.notificationService.showSuccess(
              'Poprawnie wylogowano z aplikacji'
            );
            this.router.navigate(['/login']);
          }),
          map(() => {
            return AuthActions.logoutSuccess();
          }),
          catchError((error) => {
            this.notificationService.showError(
              'Wystąpił błąd podczas wylogowywania z aplikacji'
            );
            return of(AuthActions.logoutFailure());
          })
        );
      })
    )
  );

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.login),
      switchMap((action) =>
        this.authService.getCSRFCookie().pipe(
          switchMap(() =>
            this.authService.login(action.loginData).pipe(
              tap((response) => {
                this.cookieService.setCookie('token', response.token, 7);
                this.localStorageService.save('user', {
                  name: response.user.name,
                  email: response.user.email,
                  roles: response.user.roles,
                  mobileProduction: response.user.mobile_production_version,
                  mobileMagazine: response.user.mobile_magazine_version,
                });
                this.notificationService.showSuccess(
                  'Poprawnie zalogowano do serwisu'
                );
                this.router.navigate(['/home']);
              }),
              map((response) => {
                return AuthActions.loginSuccess({
                  loginSuccessResponse: response,
                });
              }),
              catchError((error) => {
                this.notificationService.showError(error);
                return of(AuthActions.loginFailure({ error: error }));
              })
            )
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private cookieService: CookieService,
    private router: Router,
    private notificationService: NotificationService,
    private localStorageService: LocalStorageService
  ) {}
}
