import { NgModule } from '@angular/core';
import { HeaderComponent } from './components/header/header.component';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { SearchInputComponent } from './components/header/search-input/search-input.component';
import { BreadcrumbComponent } from './components/header/breadcrumb/breadcrumb.component';
import { InfoBarComponent } from './components/header/info-bar/info-bar.component';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from '../shared/shared.module';
import { MessageService } from 'primeng/api';
import { LoaderComponent } from './components/loader/loader.component';

@NgModule({
  declarations: [
    HeaderComponent,
    SideMenuComponent,
    SearchInputComponent,
    BreadcrumbComponent,
    InfoBarComponent,
    LoaderComponent,
  ],
  imports: [HttpClientModule, SharedModule],
  exports: [HeaderComponent, SideMenuComponent, LoaderComponent],
  providers: [MessageService],
})
export class CoreModule {}
