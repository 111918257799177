import { Action, createReducer, on } from '@ngrx/store';
import { getInitialParamsState } from '../../../../utils/getInitialStateFromLocalStorage';
import * as UsersActivitiesActions from './users-activities.actions';
import { IUsersActivitiesParams } from '../../models/usersActivitiesParams';

export interface UsersActivitiesState {
  queryParams: IUsersActivitiesParams;
  loading: boolean;
  error: boolean;
}

const initialState: UsersActivitiesState = {
  queryParams: getInitialParamsState<IUsersActivitiesParams>(
    'usersActivitiesParams'
  ),
  loading: false,
  error: false,
};

const _usersActivitiesReducer = createReducer(
  initialState,
  on(UsersActivitiesActions.changeQueryParams, (state, action) => ({
    ...state,
    queryParams: { ...action.queryParams },
  }))
);

export function usersActivitiesReducer(
  state: UsersActivitiesState | undefined,
  action: Action
) {
  return _usersActivitiesReducer(state, action);
}
