import { Action, createReducer, on } from '@ngrx/store';
import * as StockTakingActions from './stocktaking.actions';
import { getInitialParamsState } from '../../../../utils/getInitialStateFromLocalStorage';
import { IStocktakingParams } from '../../models/stocktakingParams';

export interface StocktakingState {
  queryParams: IStocktakingParams;
  loading: boolean;
  error: boolean;
}

const initialState: StocktakingState = {
  queryParams: getInitialParamsState<IStocktakingParams>(
    'stocktakingListQueryParams'
  ),
  loading: false,
  error: false,
};

const _stocktakingReducer = createReducer(
  initialState,
  on(StockTakingActions.changeQueryParams, (state, action) => ({
    ...state,
    queryParams: { ...action.queryParams },
  }))
);

export function stocktakingReducer(
  state: StocktakingState | undefined,
  action: Action
) {
  return _stocktakingReducer(state, action);
}
