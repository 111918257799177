import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ThemeSwitcherService {
  private linkTag!: HTMLLinkElement;
  private _currentTheme = new BehaviorSubject<string>('light-green');

  constructor(private localStorageService: LocalStorageService) {
    this.linkTag = document.createElement('link');
    this.linkTag.rel = 'stylesheet';
    document.head.appendChild(this.linkTag);
    const theme =
      this.localStorageService.read<string>('theme') || 'dark-green';
    this._currentTheme.next(theme);
    this.switchTheme(theme);
  }

  get currentTheme(): BehaviorSubject<string> {
    return this._currentTheme;
  }

  toggleThemeMode(): void {
    const theme = this._currentTheme.value;
    const newTheme = theme.includes('dark')
      ? theme.replace('dark', 'light')
      : theme.replace('light', 'dark');
    this.switchTheme(newTheme);

    newTheme.includes('dark')
      ? document.body.classList.add('dark-theme')
      : document.body.classList.remove('dark');
  }
  changeThemeColor(themeColor: string): void {
    const theme = this._currentTheme.value;
    const newTheme = theme.includes('dark')
      ? themeColor.replace('light', 'dark')
      : themeColor;

    this.switchTheme(newTheme);
  }
  switchTheme(theme: string): void {
    this.linkTag.href = `assets/themes/${theme}.css`;
    this._currentTheme.next(theme);

    this.localStorageService.save('theme', theme);
  }
}
