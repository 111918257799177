import { Action, createReducer, on } from '@ngrx/store';
import { getInitialParamsState } from '../../../../../utils/getInitialStateFromLocalStorage';
import * as PackagingTypesActions from './packaging-types.actions';
import { IPackagingTypesParams } from '../../../models/packagingTypesParams';

export interface PackagingTypesState {
  queryParams: IPackagingTypesParams;
  loading: boolean;
  error: boolean;
}

const initialState: PackagingTypesState = {
  queryParams: getInitialParamsState<IPackagingTypesParams>(
    'packagingTypesQueryParams'
  ),
  loading: false,
  error: false,
};

const _packagingTypesReducer = createReducer(
  initialState,
  on(PackagingTypesActions.changeQueryParams, (state, action) => ({
    ...state,
    queryParams: { ...action.queryParams },
  }))
);

export function packagingTypesReducer(
  state: PackagingTypesState | undefined,
  action: Action
) {
  return _packagingTypesReducer(state, action);
}
