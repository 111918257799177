import { Action, createReducer, on } from '@ngrx/store';
import { getInitialParamsState } from '../../../../../utils/getInitialStateFromLocalStorage';
import * as ClientsGroupsActions from './clients-groups.actions';
import { IClientsGroupsParams } from '../../../models/clientsGroupsParams';

export interface ClientsGroupsState {
  queryParams: IClientsGroupsParams;
  loading: boolean;
  error: boolean;
}

const initialState: ClientsGroupsState = {
  queryParams: getInitialParamsState<IClientsGroupsParams>(
    'clientsGroupsQueryParams'
  ),
  loading: false,
  error: false,
};

const _clientsGroupsReducer = createReducer(
  initialState,
  on(ClientsGroupsActions.changeQueryParams, (state, action) => ({
    ...state,
    queryParams: { ...action.queryParams },
  }))
);

export function clientsGroupsReducer(
  state: ClientsGroupsState | undefined,
  action: Action
) {
  return _clientsGroupsReducer(state, action);
}
